import { createStore } from "vuex"
import artwork from "./artwork.js"
import { getZora } from "./services.js"
import user from "./user.js"

export default createStore({
  state: {
    zora: null,
  },
  mutations: {
    setZora: (state, payload) => state.zora = payload,
    mint: (state, { mediaData, bidShares }) => state.zora.mint(mediaData, bidShares),
  },
  actions: {
    setZora: async ({ commit }) => commit("setZora", await getZora()),
  },
  modules: {
    artwork,
    ensCache: {
      namespaced: true,
      state: () => ({
        resolvedEnsNames: [],
      }),
      getters: {
        getResolvedEnsName: (state) =>
          (address) => {
            return state.resolvedEnsNames.find(ens => address === ens.address)
          },
      },
      mutations: {
        addResolvedEnsName: (state, { address, name }) => state.resolvedEnsNames.push({ address, name }),
      },
      actions: {
        addResolvedEnsName: ({ commit }, ens) => commit("addResolvedEnsName", ens),
      },
    },
    user,
  },
})
