<template>
  <header class="header">
    <h1 class="header__logo">
      <router-link class="header__link" to="/">OLTA</router-link>
    </h1>
    <UserMenu class="header__user-menu" />
    <div
      class="header__menus"
      :class="{'is-open': isOpen}"
      >
      <ul class="header__menu">
        <li class="header__item" v-for="item in menu" :key="item">
          <router-link
            class="header__link" :to="item.link"
            @click="toggleMenu"
            >
            {{ item.label }}
          </router-link>
        </li>
        <li class="header__item">
          <a
            class="header__link"
            href="https://mirror.xyz/0xcEC95E3B9DD2C1CD5Be1340bF9F60d89AbdFcFb1"
            target="_blank"
            @click="toggleMenu"
            >
            blog
          </a>
        </li>
      </ul>
    </div>
    <div
      class="header__menu-button"
      :class="{'is-open': isOpen}"
      @click="toggleMenu"
      >
      <span/>
      <span/>
      <span/>
      <span/>
    </div>
  </header>
</template>

<script>
import { defineComponent, ref } from "vue"
import UserMenu from "./UserMenu.vue"

export default defineComponent({
  components: {
    UserMenu
  },
  setup() {
    const menu = ref([
      {
        label: "Art",
        link: "/artworks",
      },
      // {
      //   label: "Users",
      //   link: "/profiles",
      // },
      {
        label: "about",
        link: "/about",
      },
      {
        label: "faq",
        link: "/faq",
      },
    ])
    const isOpen = ref(false)
    const toggleMenu = () => isOpen.value = !isOpen.value

    return {
      menu,
      isOpen,
      toggleMenu
    }
  },
})
</script>

<style lang="scss" scoped>
.header {
  @extend %gradient-border;
  align-items: center;
  background: $primary-background;
  border-bottom: solid $border-width;
  display: flex;
  height: $header-height;
  justify-content: space-between;
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 99;
  &__menus {
    position: absolute;
    top: calc($border-width + 100%);
    width: 100%;
    transform: translateX(0);
    transition: transform 200ms;
    @extend %frosted-background;
    display: none;
    &.is-open {
      display: block;
    }
  }
  &__menu {
    @include plain-list;
    margin: $margin;
  }
  &__logo {
    @include plain-link;
    line-height: 1;
    margin: 0 $margin-small 0 $margin - $button-padding;
    padding: $button-padding;
  }
  &__item {
    font-weight: bold;
    font-size: 3rem;
    text-align: right;
    text-transform: lowercase;
    &:hover {
      text-decoration: underline;
    }
  }
  &__link {
    @include plain-link;
  }
  &__user-menu {
    text-align: right;
    margin: $margin-small;
    width: 100%;
  }
  &__menu-button {
    padding: $button-padding;
    margin-right: $margin;
    width: $margin-small * 2;
    height: $margin-small;
    position: relative;
    cursor: pointer;
    span {
      display: block;
      position: absolute;
      height: $margin-small * 0.5;
      width: 100%;
      background: $primary-color;
      left: 0;
      transform: rotate(0);
      transition: .25s ease-in-out;
      &:nth-child(1) {
        top: 0;
      }
      &:nth-child(2), &:nth-child(3) {
        top: $margin-small;
      }
      &:nth-child(4) {
        top: $margin-small * 2;
      }
    }
    &.is-open span {
      &:nth-child(1) {
        top: $margin-small;
        width: 0;
        left: 50%;
      }
      &:nth-child(2) {
        transform: rotate(45deg);
      }
      &:nth-child(3) {
        transform: rotate(-45deg);
      }
      &:nth-child(4) {
        top: $margin-small;
        width: 0;
        left: 50%;
      }
    }
  }
}
</style>
