<template>
  <div
    class="eth-address-container"
    :class="popUpPositionClass"
  >
    <div
      ref="popUp"
      class="pop-up"
    >
      <button
        class="copy-button"
        @click="copyEthAddress"
      >
        {{ copyLabel }}
      </button>
      <span
        ref="ethAddressToCopy"
        class="eth-address"
      > {{ ethAddress }}</span>
    </div>
    <router-link
      :to="profileLink"
      class="profile-link"
    >
      <span
        v-if="!ensName"
        class="shortend"
      >
        {{ shortened }}
      </span>
      <span
        v-else
        class="ens"
      >
        {{ ensShortend }}
      </span>
    </router-link>
  </div>
</template>

<script>
import { computed, defineComponent, toRefs, ref, onMounted, watch} from "vue"
import { useStore } from "vuex"
import ENS, { getEnsAddress } from '@ensdomains/ensjs'
import { utils } from 'ethers'
import { getEthersProvider } from '@/services.js'

export default defineComponent({
  name: 'EthAddress',
  props:{
    ethAddress: String
  },
  setup(props) {
    const store = useStore()
    const getResolvedEnsName = (address) => store.getters['ensCache/getResolvedEnsName'](address)
    const addResolvedEnsName = ({address, name}) => store.dispatch("ensCache/addResolvedEnsName", {address, name})
    const { ethAddress } = toRefs(props)
    const isAddress = computed(() => utils.isAddress(ethAddress.value))
    const ensName = ref()
    const ethAddressToCopy = ref()
    const popUp = ref()

    const resolveEnsName = async (address) => {
      try {
        const provider = await getEthersProvider()
        if(!provider) return

        const ens = new ENS({ provider, ensAddress: getEnsAddress('1') })
        const { name } = await ens.getName(address)

        // Check to be sure the reverse record is correct.
        const reverseRecord = await ens.name(name).getAddress()
        if(address !== reverseRecord.toLowerCase()) return

        return name
      } catch (error) {
        console.log(`couldn't resolve ens name for ${address}`)
        return
      }
    }

    const getEns = async (address) => {
      if(!address) return
      // check cache
      const cache = getResolvedEnsName(address)
      if(cache) return cache.name

      const name = await resolveEnsName(address)
      if(!name) return

       // cache record
      addResolvedEnsName({address, name})
      return name
    }

     watch(ethAddress, async () => ensName.value = await getEns(ethAddress.value))

    const ensShortend = computed(() => {
      if(!ensName.value) return

      if(ensName.value.length > 19) {
        return ensName.value.slice(0,18) + '...'
      }

      return ensName.value
    })

    const profileLink = computed(() => `/profiles/${ethAddress.value}`)

    const shortened = computed(() => {
      // check is eth address
      if(isAddress.value){
        const start = ethAddress.value.slice(0,6)
        const end = ethAddress.value.slice(-4)
        return `${start}...${end}`
      }

      return
    })

    const copyLabel = ref('Copy')
    const copyEthAddress = () => {
      const textArea = document.createElement("textarea")
      textArea.value = ethAddress.value
      document.body.appendChild(textArea)
      textArea.select()
      document.execCommand("copy")
      textArea.remove()

      // let user know it's been copied
      copyLabel.value = 'Copied'
      setTimeout(() => copyLabel.value = 'Copy', 1500)
    }


    const checkBounds = (element) => {
      if(!element) return

      const bounding = element.getBoundingClientRect()
      const width = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth

      const top = (bounding.top >= 0)
      const right = (bounding.right <= width)

      return {
        top,
        right,
      }
    }

    const popUpPositionClass = computed(() => {
       if(!popUp.value) return
       const isInBounds = checkBounds(popUp.value)
       return {
         right: !isInBounds.right,
         top: !isInBounds.top
       }
    })

    onMounted(async () => {
      ensName.value = await getEns(ethAddress.value)
    })

    return {
      profileLink,
      shortened,
      ensName,
      ensShortend,
      copyEthAddress,
      ethAddressToCopy,
      copyLabel,
      popUp,
      popUpPositionClass,
    }
  },
});
</script>

<style lang="scss" scoped>
.pop-up{
  display: none;
}
.eth-address-container{
  display: inline-block;
}
</style>