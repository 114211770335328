<template>
  <div class="user-nav">
    <ul class="user-actions">
      <LoginButton />
    </ul>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import LoginButton from './LoginButton.vue'

export default defineComponent({
  components:{
    LoginButton
  },
})
</script>

<style lang="scss" scoped>
  .user-actions{
    @include plain-list
  }
</style>
