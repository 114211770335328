import {
  createComsForPlatform,
  createMediaQuery,
  firebaseApp as firebase,
  firestore,
  getZora,
  zoraGraph,
  zoraGraphRinkeby,
} from "@/services.js"
import { approveERC20, constructAsk, constructBid } from "@zoralabs/zdk"
import * as Comlink from "comlink"
import { utils } from "ethers"

export default {
  namespaced: true,
  state: () => ({
    doc: null, // firestore document
    iframeRef: null, // refrence to the iframe that displays the artwork
    currentToolbarTab: null,
  }),
  getters: {
    getData: state => state.doc ? state.doc.data() : null,
    getRef: state => state.doc ? state.doc.ref : null,
    getId: state => state.doc ? state.doc.id : null,
    getZoraMediaId: (state, getters, rootState) => {
      if (rootState.user.chainId === "Rinkeby") {
        return state.doc ? state.doc.data().zoraMediaIdRinkeby : false
      }
      if (rootState.user.chainId === "Mainnet") {
        return state.doc ? state.doc.data().zoraMediaId : false
      }
      // show mainnet if there is no chain id
      if (rootState.user.chainId === null) {
        return state.doc ? state.doc.data().zoraMediaId : false
      }
      // return false when the chain Id is not recognised
      return false
    },
    getSnippet: state => {
      const artworkDoc = (state.doc)
      if (!artworkDoc) {
        console.error("ERROR: artwork/getSnippet, no artwork doc")
        return
      }
      const { name, artist, uri, thumbnail } = artworkDoc.data()
      const snippet = {
        uid: artworkDoc.id,
        name,
        uri,
        thumbnail,
        artist,
      }
      return snippet
    },
  },
  mutations: {
    setDoc: (state, doc) => state.doc = doc,
    setIframeRef: (state, iframeRef) => state.iframeRef = iframeRef,
    setCurrentToolbarTab: (state, tabName) => state.currentToolbarTab = tabName,
    reset: state => {
      state.doc = null
      state.iframeRef = null
      state.currentToolbarTab = null
    },
  },
  actions: {
    setIframeRef: ({ commit }, iframeRef) => commit("setIframeRef", iframeRef),
    loadFromFirebase: async ({ commit }, artworkURI) => {
      if (!artworkURI) return
      // artworks Ref
      const db = firestore
      const artworksRef = db.collection("artworks")

      // query for artwork docs with URI
      try {
        const query = await artworksRef.where("uri", "==", artworkURI).limit(1).get()
        if (!query.empty) {
          // should only be one
          commit("setDoc", query.docs[0])
          return
        }
      } catch (error) {
        // TODO: handle errors
        console.error("Error while loading artwork from database", error)
        return
      }
    },
    setCurrentToolbarTab: ({ commit, state }, tabName) => {
      if (tabName !== state.currentToolbarTab) {
        commit("setCurrentToolbarTab", tabName)
      } else {
        commit("setCurrentToolbarTab", null)
      }
    },
    reset: ({ commit, dispatch }) => {
      commit("reset")
      dispatch("artwork/market/reset", null, { root: true })
      dispatch("artwork/optionsV2/reset", null, { root: true })
    },
  },
  modules: {
    options: {
      namespaced: true,
      state: () => ({
        exposed: null, // functions and state exposed by artwork iframe
        artworkOptions: null,
        artworkControllers: null,
      }),
      getters: {
        getUserSavesRef: (state, getters, rootState, rootGetters) => {
          const artworkRef = rootGetters["artwork/getRef"]
          const uid = rootGetters["user/getUid"]
          if (artworkRef && uid) {
            return artworkRef.collection("saves").doc(uid)
          }

          return null
        },
      },
      mutations: {
        setExposed: (state, payload) => state.exposed = payload,
        setOptions: (state, payload) => state.artworkOptions = payload,
        setControllers: (state, payload) => state.artworkControllers = payload,
        updateOption: (state, { property, value }) => {
          state.artworkOptions[property] = value
          if (state.exposed.updateOption) {
            state.exposed.updateOption(property, value)
          } else {
            console.log("cant update artwork option")
          }
        },
        reset: state => {
          state.exposed = null
          state.artworkOptions = {}
        },
      },
      actions: {
        setExposed: ({ commit }, payload) => {
          commit("setExposed", payload)
        },
        retrieveExposedFunctions: async ({ commit, rootState, dispatch }) => {
          // get iframe where artwork is being displayed
          const iframeRef = rootState.artwork.iframeRef
          if (!iframeRef) return

          // establish comlink and get the exposed functions
          const getEndpoint = ref => Comlink.windowEndpoint(ref.contentWindow)
          const exposedFunctions = Comlink.wrap(getEndpoint(iframeRef))
          if (!exposedFunctions) return
          const { getOptions, updateOption } = exposedFunctions
          commit("setExposed", {
            getOptions,
            updateOption,
          })
          dispatch("setOptions")
          return
        },
        setOptions: async ({ state, commit }) => {
          if (!state.exposed) {
            console.log("no exposed")
            return
          }
          const { options, controllers } = await state.exposed.getOptions()

          commit("setOptions", options)
          commit("setControllers", controllers)
        },
        save: async ({ state, rootState, getters }) => {
          const userSavesRef = getters.getUserSavesRef
          if (!userSavesRef) return

          try {
            if (userSavesRef && state.artworkOptions) {
              await userSavesRef.set({
                ethAddress: rootState.user.account,
                timestamp: Date.now(),
                slotOne: state.artworkOptions,
              }, { merge: true })
              return
            } else {
              console.log("no save ref")
              return
            }
          } catch (error) {
            console.error("Error writing document", error)
            return
          }
        },
        load: async ({ dispatch, state, getters }) => {
          const userSavesRef = getters.getUserSavesRef
          if (!userSavesRef) return

          try {
            const saves = await userSavesRef.get()
            if (saves.exists) {
              const slotOne = saves.data().slotOne
              // updateOptions(optionsToSave.value, slotOne)
              dispatch("updateOptions", slotOne)
            } else {
              // load defualt
              const defualt = await state.exposed.getOptions()
              dispatch("updateOptions", defualt)
            }
            return
          } catch (error) {
            console.error("Error writing document: ", error)
            return
          }
        },
        updateOptions: ({ commit, dispatch }, payload) => {
          for (const key in payload) {
            if (typeof payload[key] === "object") {
              // Recusive update
              dispatch("updateOptions", payload[key])
            } else {
              // update store and iframe(artwork) values
              commit("updateOption", { property: key, value: payload[key] })
            }
          }
        },
        reset: ({ commit }) => commit("reset"),
      },
    },
    optionsV2: {
      namespaced: true,
      state: () => ({
        coms: null, // functions and state exposed by artwork iframe
        save: null,
        latestLoad: null,
        controllers: null,
        guiObject: null,
      }),
      getters: {
        hasComs: (state) => state.coms ? true : false,
        getUserSavesRef: (state, getters, rootState, rootGetters) => {
          const artworkRef = rootGetters["artwork/getRef"]
          const uid = rootGetters["user/getUid"]
          if (artworkRef && uid) {
            return artworkRef.collection("saves").doc(uid)
          }

          return null
        },
      },
      mutations: {
        setGuiObject: (state, payload) => state.guiObject = payload,
        updateGuiObject: (state) => {
          if (state.guiObject) {
            for (const [key, prop] of Object.entries(state.latestLoad.data)) {
              if (typeof state.guiObject[key] === undefined) return

              state.guiObject[key] = prop
            }
          }
        },
        triggerUpdate: (state) => {
          if (state.latestLoad && state.coms) {
            const data = JSON.parse(JSON.stringify(state.latestLoad.data))
            state.coms.load(data)
          }
        },
        setLatestLoad: (state, payload) => {
          state.latestLoad = payload
          if (state.coms) {
            state.coms.load(payload.data)
          }
        },
        setComs: (state, payload) => state.coms = payload,
        setSave: (state, payload) => {
          console.log("setting save to", payload)
          state.save = payload
        },
        setControllers: (state, payload) => {
          // console.log("setting controllers to", payload)
          state.controllers = payload
        },
        reset: state => {
          state.coms = null
          state.save = null
          state.latestLoad = null
          state.guiObject = null
          state.controllers = null
        },
      },
      actions: {
        setupComs: ({ rootState, rootGetters, commit, dispatch }) => {
          const iframeRef = rootState.artwork.iframeRef
          // TODO: get base url with port for createComsForPlatform
          // console.log('contnetURL', rootGetters['artwork/getData'].contentURL)
          // somthing like this https://stackoverflow.com/questions/1420881/how-to-extract-base-url-from-a-string-in-javascript
          const artworkData = rootGetters["artwork/getData"]
          if(!artworkData) return
          if(!artworkData.contentURL) return

          const coms = createComsForPlatform(iframeRef, url)
          // TODO: dispatch setups
          coms.onSetControllers((controllers) => {
            commit("setControllers", controllers)
          })
          coms.onGetSave((data) => dispatch("onGetSave", data))
          coms.start()
          commit("setComs", coms)
          dispatch("getControllers")
          commit("triggerUpdate")
        },
        getControllers: ({ state }) => state.coms.getControllers(),
        save: ({ state }) => {
          if (!state.coms) {
            return
          }
          // triggers artwork to send (via postMessage) data to save
          state.coms.save()
        },
        onGetSave: async ({ dispatch, rootState }, data) => {
          if (!data) return
          if (!rootState.user.account) return
          try {
            await dispatch("saveToFirebase", {
              ethAddress: rootState.user.account,
              slot: "0",
              data,
            })
            return
          } catch (error) {
            console.error("Error writing document", error)
            return
          }
        },
        onControllerUpdate: ({ state }, { property, value }) => {
          // send update to artwork ( via post message )
          state.coms.controllerUpdate({ property, value })
        },

        // New saves structure
        loadFromFirebase: async ({ commit }, { ethAddress, artwork, slot, cache }) => {
          if (ethAddress === undefined || artwork.uid === undefined || slot === undefined) {
            console.error("ERROR on getSave: please specify ethAddress, artworkUID and slot")
            return
          }

          // TODO: check if in state.slots already

          // saves Ref
          const db = firestore
          const savesRef = db.collection("saves")
          const query = savesRef
            .where("ethAddress", "==", ethAddress)
            .where("artwork.uid", "==", artwork.uid)
            .where("slot", "==", slot)
            .limit(1)

          const result = await query.get()
          if (result.empty) return { exists: false }

          const doc = result.docs[0]
          if (cache) {
            // Store as latest load
            commit("setLatestLoad", doc.data())
            commit("updateGuiObject")
          }
          return doc
        },
        loadFromID: async ({ commit }, saveId) => {
          const db = firestore
          const saveRef = db.collection("saves").doc(saveId)
          const save = await saveRef.get()
          if (!save.exists) return save
          // Store as latest load
          commit("setLatestLoad", save.data())
          commit("updateGuiObject")
          return save
        },
        saveToFirebase: async ({ dispatch, rootGetters, commit }, { ethAddress, slot, data }) => {
          if (ethAddress === undefined || slot === undefined) {
            console.error("ERROR on getSave: please specify ethAddress, artworkUID and slot")
            return
          }
          if (typeof data !== "object") {
            console.error("ERROR on getSave: save data must be an object")
            return
          }

          // get current artwork snippet
          const artwork = rootGetters["artwork/getSnippet"]

          // construct save
          const save = {
            ethAddress,
            timestamp: firebase.firestore.FieldValue.serverTimestamp(),
            slot,
            data,
            artwork,
          }

          // get saveRef from firebase / store
          const saveSnapshot = await dispatch("loadFromFirebase", {
            artwork,
            ethAddress,
            slot,
          })

          if (saveSnapshot.exists) {
            // update save
            await saveSnapshot.ref.set(save, { merge: true })
            commit("setLatestLoad", {
              ...save,
              timestamp: firebase.firestore.Timestamp.now(),
              id: saveSnapshot.ref.id,
            })
          } else {
            // create new save
            const savesRef = firestore.collection("saves")
            const newSave = savesRef.doc()
            await newSave.set({ ...save, id: newSave.id })
            commit("setLatestLoad", {
              ...save,
              id: newSave.id,
              timestamp: firebase.firestore.Timestamp.now(),
            })
          }

          return
        },
        reset: ({ commit }) => commit("reset"),
      },
    },
    market: {
      namespaced: true,
      state: () => ({
        media: null,
      }),
      getters: {},
      mutations: {
        setMedia: (state, media) => state.media = media,
        reset: (state) => state.media = null,
      },
      actions: {
        requestMedia: async ({ commit, rootGetters, rootState }, mediaId) => {
          const id = mediaId ? mediaId : rootGetters["artwork/getZoraMediaId"]
          // console.log("id", id)
          if (!id) {
            commit("setMedia", false)
            return false
          }

          const mediaQuery = createMediaQuery(id)
          const getMedia = async () => {
            const chainId = rootState.user.chainId
            if (chainId === "Rinkeby") {
              return await zoraGraphRinkeby.request(mediaQuery)
            }
            if (chainId === "Mainnet") {
              return await zoraGraph.request(mediaQuery)
            }
            if (chainId === null) {
              // not connected to metamask
              return await zoraGraph.request(mediaQuery)
            }
            return {}
          }

          const { media } = await getMedia()
          commit("setMedia", media)
          return media
        },
        approveBid: async (context, { amount, currency }) => {
          const zora = await getZora()
          const wallet = zora.signerOrProvider

          const amountBN = utils.parseUnits(amount, currency.decimals)
          const approval = await approveERC20(wallet, currency.address, zora.marketAddress, amountBN)

          return approval
        },
        placeBid: async ({ state }, { amount, currency, sellOnShare }) => {
          // TODO: add tokens
          const zora = await getZora()
          const wallet = zora.signerOrProvider
          const amountBN = utils.parseUnits(amount, currency.decimals)
          const bidderAddress = await wallet.getAddress()
          const bid = constructBid(
            currency.address,
            amountBN,
            bidderAddress,
            bidderAddress,
            Number(sellOnShare),
          )

          // transaction
          const mediaId = state.media.id
          const tx = await zora.setBid(mediaId, bid)
          return tx
        },
        // TODO: removeBid
        setAsk: async ({ state }, { amount, currency }) => {
          const zora = await getZora()
          const mediaId = state.media.id
          const amountBN = utils.parseUnits(amount, currency.decimals)
          const ask = constructAsk(
            currency.address,
            amountBN,
          )

          const isVaild = await zora.isValidAsk(mediaId, ask)
          // console.log("isValid", isVaild)
          if (!isVaild) return

          const tx = await zora.setAsk(mediaId, ask)
          return tx
        },
        removeAsk: async ({ state }) => {
          const zora = await getZora()
          const mediaId = state.media.id
          const tx = await zora.removeAsk(mediaId)
          return tx
        },
        acceptBid: async ({ state }, bid) => {
          // TODO: format bid
          const zora = await getZora()
          // console.log("bid", bid)
          // console.log('bid', bid)
          // TODO: search currency decimals
          const constructedBid = constructBid(
            utils.getAddress(bid.currency.id),
            bid.amount,
            utils.getAddress(bid.bidder.id),
            utils.getAddress(bid.bidder.id),
            Number(utils.formatUnits(bid.sellOnShare, "ether")),
          )

          // console.log('constructed bid', constructedBid)
          const mediaId = state.media.id
          const tx = await zora.acceptBid(mediaId, constructedBid)
          return tx
        },
        removeBid: async ({ state }) => {
          const zora = await getZora()
          const mediaId = state.media.id
          const tx = await zora.removeBid(mediaId)
          return tx
        },
        reset: ({ commit }) => commit("reset"),
      },
    },
  },
}
