<template>
<div class="container">
  <div class="artwork">
    <!-- thumbnail -->
    <picture></picture>
    <!-- embeded work -->
    <ArtworkContent
      class="artwork__content"
      :artwork="artwork"
      :width="'100%'"
      :show-notice="false"
      :camera="false"
      :fullscreen="false"
    />
    <!-- info -->
    <div class="artwork__info-overlay"></div>
    <div class="artwork__info">
      <router-link
        class="artwork__info__artwork-link"
        :to="`/artworks/${artwork.uri}`"
        >
        {{ artwork.title }}
      </router-link>
      <router-link
        class="artwork__info__artist-link"
        :to="`/profiles/${creator.ethAddress}`"
        >
        {{ creator.name }}
      </router-link>
      <!-- <div class="artwork__info__meta">
        <span class="artwork__info__meta__countdown">05 : 12 : 57</span>
        <span class="artwork__info__meta__current-bid">1.5 ETH</span>
      </div> -->
      <router-link
        class="artwork__info__view-button"
        :to="`/artworks/${artwork.uri}`"
        >
        view
      </router-link>
    </div>

  </div>
  <div
    class="block--alt"
    >
    <div class="cta" @click="scrollToAnchor">
      <h2 class="cta__text">{{ cta }}</h2>
      <p class="cta__arrow">v</p>
    </div>
    <div ref="anchor" class="block__anchor"></div>
  </div>
</div>
</template>

<script>
import { defineComponent, computed, ref } from "vue"
import ArtworkContent from './ArtworkContent.vue'

export default defineComponent({
  components:{
    ArtworkContent
  },
  props: {
    artwork: Object,
    cta: String
  },
  setup(props) {
    // TODO: artwork thumbnail image
    // TODO: format artwork details, at the moment they are just hard coded
    // TODO: 'tap to play' overlay
    // TODO: Compute auction countdown
    // is auction started ? countdown + currentBid : reserve price

    // CTA scroll down button
    const anchor = ref(null)
    const scrollToAnchor = () => {
      anchor?.value?.scrollIntoView({
        behavior: "smooth",
        block: "start",
      })
    }

    const creator = computed(() => {
      if (!props.artwork || !props.artwork.artist) {
        return
      }

      const creator = { ...props.artwork.artist }

      // no eth address fallback
      if (!props.artwork.artist.ethAddress) {
        creator.ethAddress = creator.uri
      }

      return creator
    })

    return {
      anchor,
      scrollToAnchor,
      creator,
    }
  },
})
</script>

<style lang="scss" scoped>
.container {
  display: grid;
  grid-template-rows: 50% 50%;
  height: calc(100vh - $header-height);
  @media (min-aspect-ratio: 1/1) {
    grid-template-columns: 2fr 1fr;
    grid-template-rows: unset;
  }
}
.artwork {
  position: relative;
  &__content {
    height: 100%;
    padding: 0;
  }
  &__info-overlay {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    top: 50%;
    background: $gradient-overlay;
    pointer-events: none;
  }
  &__info {
    position: absolute;
    bottom: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: $margin;
    color: $primary-background;
    z-index: 5;
    &__artist-link, &__artwork-link {
      @include plain-link($secondary-color);
      &:hover {
        text-decoration: underline;
      }
    }
    &__artwork-link {
      font-weight: bold;
    }
    &__meta {
      margin-top: $margin-small;
      font-size: 80%;
      span + span {
        &::before {
          content: "|";
          margin: 0 $margin-small;
        }
      }
    }
    &__view-button {
      @include plain-link($primary-background);
      cursor: pointer;
      font-weight: bold;
      padding: $margin-small $margin-small * 2;
      display: block;
      margin: $margin-small 0 0;
      font-size: 120%;
      background: $gradient-highlight;
      background-clip: initial;
      -webkit-text-fill-color: initial;
      color: $primary-background;
      position: relative;
      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background: black;
        z-index: -1;
        @extend %gradient-border;
        border-style: solid;
        border-width: $border-width;
      }
      &:hover {
        @extend %gradient-text;
      }
    }
  }
}
.block {
  &--alt {
    position: relative;
    background: $primary-color;
  }
  &__anchor {
    position: absolute;
    bottom: $header-height;
  }
}
.cta {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  cursor: pointer;
  &__text {
    font-size: 3rem;
    font-weight: bold;
    color: $primary-background;
    text-align: center;
    word-spacing: 100vh; // one word per line
    line-height: 1.1;
    margin: 0;
  }
  &__arrow {
    @extend %gradient-text;
    font-size: 3rem;
    margin: 0;
    cursor: pointer;
  }
}
</style>
