<template>
  <g>
		<g>
				<line fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" x1="63.514" y1="26.455" x2="51.625" y2="38.342"/>
		</g>
			<polyline fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" points="
			63.545,37.203 63.545,26.417 52.803,26.417 		"/>
	</g>
	<g>
		<polyline fill="none" stroke-miterlimit="10" points="13.962,43.651 45.617,43.651 
			45.617,66.124 		"/>
	</g>
	<g>
			<rect x="14.033" y="18.875" fill="none" stroke-miterlimit="10" width="57.004" height="47.167"/>
	</g>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: "icon-fullscreen"
})
</script>
