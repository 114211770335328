<template>
  <div
    v-if="!account && !loading"
    class="connect-to-metamask button"
    @click="connectAndSignInViaMetaMask"
  >
    <span class="connect">Connect to MetaMask</span>
  </div>
  <div
    v-else-if="!loading"
    class="account-details button"
  >
    <span
      v-if="chainId"
      class="chain-id"
    > {{ chainId }}</span>
    <eth-address
      v-if="hasAccount"
      class="account no-pop-up"
      :eth-address="account"
    />
    <span
      v-if="hasCredentials"
      class="verified-symbol"
    />
  </div>
  <span v-else>...</span>
</template>

<script>
import { defineComponent, computed, onMounted, ref } from "vue"
import { useStore } from 'vuex'
import { getEthersProvider } from '@/services.js'
import EthAddress from './EthAddress.vue'

export default defineComponent({
  name: 'LoginButton',
  components:{
    EthAddress
  },
  setup() {
    const store = useStore()
    const account = computed(() => store.state.user.account)
    const chainId = computed(() => store.state.user.chainId)

    const hasAccount = computed(() => store.getters['user/hasAccount'])
    const hasCredentials = computed(() => store.getters['user/hasCredentials'])

    const setAuthStateChangeHandler = () => store.dispatch('user/setAuthStateChangeHandler')
    const connectToMetaMask = () => store.dispatch('user/connectToMetaMask')
    const connectAndSignInViaMetaMask = () => store.dispatch('user/connectAndSignInViaMetaMask')

    const isAlreadyConnectedToMetamask = async () => {
      try {
        const provider = await getEthersProvider()
        if(!provider) return

        const connectedAccounts = await provider.listAccounts()

        return connectedAccounts.length > 0
      } catch (e) {
        console.log(e.message)
      }

      return false
    }

    const loading = ref(true)

    onMounted(async () => {
      setAuthStateChangeHandler()

      try {
        const isAlreadyConnected = await isAlreadyConnectedToMetamask()

        if (isAlreadyConnected) {
          connectToMetaMask()
        }
      } catch (e) {
        console.log(e)
      }

      loading.value = false
    })

    return {
      account,
      chainId,
      hasAccount,
      hasCredentials,
      connectAndSignInViaMetaMask,
      loading
    }
  },
})
</script>

<style lang="scss" scoped>
  .chain-id{
    margin: $margin-small;
    opacity: 0.5;
    text-decoration: none;
    font-size: 80%;
  }
  .connect-to-metamask.button{
    cursor: pointer;
    user-select: none;
  }
</style>
