<template>
  <div class="cta" :class="{ ['--hover'] : hover }">
    <h2 class="cta__content"><slot></slot></h2>
    <router-link
      :to="link"
      class="cta__button"
      @mouseover="toggleHover"
      @mouseleave="toggleHover"
      >{{ buttonLabel }}
    </router-link>
  </div>
</template>

<script>
import { defineComponent, ref } from "vue"

export default defineComponent({
  name: "Call To Action",
  props:{
    tagline: String,
    buttonLabel: String,
    link: String
  },
  setup() {
    const hover = ref(false)
    const toggleHover = () => hover.value = !hover.value
    return {
      hover,
      toggleHover
    }
  }
})
</script>

<style lang="scss" scoped>
  .cta{
    @extend %gradient-border;
    border-style: solid;
    border-width: $border-width 0 $border-width 0;
    padding: $margin;
    &__content{
      font-size: 300%;
      line-height: 1;
      max-width: 6em; // Keep the words neat
    }
    &__button{
      @include plain-link;
      border-style: solid;
      border-width: $border-width;
      @extend %gradient-border;
      @extend %gradient-text;
      font-weight: bold;
      padding: $margin-small $margin-small * 2;
      margin-bottom: $margin;
      display: inline-block;

      &:hover{
        background: $gradient-highlight;
        background-clip: initial;
        -webkit-text-fill-color: initial;
        color: $primary-background;
      }
    }
    &.--hover{
      background: $primary-color;
      .cta__content{
        color: $primary-background;
      }
    }
  }
</style>
