<template>
  <div
    v-if="artwork"
    class="artwork-content"
    :style="aspectRatioStyle"
  >
    <iframe
      ref="artworkRef"
      class="artwork-content__iframe"
      :allow="allow"
      allowvr="yes"
      scrolling="no"
      sandbox="allow-scripts allow-same-origin"
      :src="artwork.latestVersion"
      :srcdoc="srcdoc"
      :width="width"
      :height="height"
      @load="onArtworkLoad"
    />
    <div v-if="(fullscreen && isFullscreenEnabled) && !isVrEnabled" class="fullscreen-button" @click="toggleFullScreen">
      <Icon
        class="fullscreen-button__icon"
        :width="'4em'"
        :height="'4em'"
        :stroke-width="3"
        :customViewBox="'0 0 85 85'"
      >
        <IconFullScreen class="fullscreen-button__icon__svg"/>
      </Icon>
    </div>
    <div v-if="showDisplayNotice && displayNotice" class="notice">
      <p class="notice__text">{{ displayNotice }}</p>
      <div class="notice__close" @click="closeNotice">X</div>
    </div>
    <div
      v-if="!isLoaded"
      class="loading animated-background"
    ></div>
  </div>
</template>

<script>
import { defineComponent, ref, computed, onMounted} from "vue"
import fscreen from "fscreen"
import { useStore } from "vuex"
import { getIframeSrcdoc } from "./../helper.js"
import Icon from "./Icon.vue"
import IconFullScreen from './IconFullScreen.vue'
import UAParser from 'ua-parser-js'
export default defineComponent({
  name: 'ArtworkContent',
  components:{
    Icon,
    IconFullScreen
  },
  props: {
    artwork: Object,
    height: String,
    width: String,
    camera: {
      type: Boolean,
      default: true
    },
    fullscreen: {
      type: Boolean,
      default: true
    },
    showNotice: {
      default: true,
      type: Boolean
    }
  },
  setup(props, {emit}) {
    const store = useStore()
    const setIframeRef = (iframeRef) => store.dispatch("artwork/setIframeRef", iframeRef)
    const setupComs = () => store.dispatch("artwork/optionsV2/setupComs")
    // artwork iframe HTML Element ref
    const artworkRef = ref()
    const srcdoc = ref()

    // allow artworks access to data
    // NOTE[@george] may run into vr issues on ios see: https://stackoverflow.com/questions/40652576/how-do-i-run-webvr-content-within-in-an-iframe
    const allowPolicy = `camera *; fullscreen *; autoplay *; accelerometer *; gamepad *; gyroscope *; magnetometer *; microphone *; xr-spatial-tracking *; usb *;`
    const allow = computed(() => props.camera ? allowPolicy : '')

    const isFullscreenEnabled = computed(() => fscreen.fullscreenEnabled)
    const isArtworkFullscreen = () => fscreen.fullscreenElement === artworkRef.value

    const makeFullscreen = async () => {
      try{
        await fscreen.requestFullscreen(artworkRef.value)
      } catch (error) {
        alert(`Error attempting to enable full-screen mode: ${error.message} (${error.name})`)
      }
    }

    // Toggle fullscreen function
    const toggleFullScreen = () => {
      if(!artworkRef.value) return
      if(!isFullscreenEnabled) return

      isArtworkFullscreen() ? fscreen.exitFullscreen() : makeFullscreen()
    }

    // Defualts to square enless height prop is specified
    const aspectRatioStyle = computed(() => {
      if(!props.height) return

      return {
        height: props.height,
        padding: 0
      }
    })

    // Loading
    const isLoaded = ref(false)
    const onArtworkLoad = () => {
      setIframeRef(artworkRef)
      // HACK: don't setup coms for now
      // setupComs()
      emit('loaded')
      isLoaded.value = true
    }

    // Show device notice
    const parser = new UAParser()
    const isString = (value) => (typeof value === 'string') || (value instanceof String)

    const displayNotice = computed(() => {
      if(!props.artwork) return
      const notices = props.artwork.notices

      if(!notices || notices.length === 0) return
      const uaResults = parser.getResult()
      console.log("ua", uaResults)

      // loop through all notices
      const matches = notices.filter(({text, ua}) => {
        // Possible Types: browser, cpu, device, engine, os
        const types = Object.keys(ua)
        const matchingTypes = types.filter(type => {
          if(!uaResults[type]) return
          // check all propertys of user agent catagory type matches
          const props = Object.keys(ua[type])
          const matchingProps = props.filter(prop => {
            if(!isString(ua[type][prop])) return
            if(!uaResults[type][prop]) return
            // check if prop matches
            return (ua[type][prop] === uaResults[type][prop].toLowerCase())
          })
          // check all props of types match
          return (matchingProps.length === props.length)
        })
        // check all types match
        return matchingTypes.length === types.length
      })

      // check if there are any matches
      if (!matches || matches.length === 0) return
      console.log("matches", matches)

      // return first notice that matches
      return matches[0].text
    })

    const showDisplayNotice = ref(props.showNotice)
    console.log("showNotice", props.showNotice)
    const closeNotice = () => showDisplayNotice.value = false

    // Hack: hide fullscreen button if artwork is VR and on VR device
    // see also onMonted() and fullscreen button html
    const isVrSupported = ref(false)
    const isVrEnabled = computed(() => {
      if(!props.artwork) return
      if(!props.artwork.features) return
      return (isVrSupported.value && props.artwork.features.vr)
    })

    onMounted(async () => {
      if(navigator.xr){
        isVrSupported.value = await navigator.xr.isSessionSupported('immersive-vr')
      }
      // only use srcdoc on artworks that work
      if(props.artwork.srcDoc == false) return
      // only use srcdoc for artworks stored on arweave
      if(!props.artwork.latestVersion.includes("arweave.net")) return
      // HACK: don't use for meanwhile
      if(props.artwork.latestVersion.includes("4r-23-tQlcMwg4FdEA80GCpLZvzHNnro25UvY7WB5m0")) return
      srcdoc.value = await getIframeSrcdoc(props.artwork.latestVersion)
    })

    return {
      artworkRef,
      isFullscreenEnabled,
      toggleFullScreen,
      isLoaded,
      onArtworkLoad,
      aspectRatioStyle,
      allow,
      displayNotice,
      showDisplayNotice,
      closeNotice,
      isVrEnabled,
      srcdoc,
    }
  },
})
</script>

<style lang="scss" scoped>
.artwork-content{
  position: relative;
  width:100%;
  padding-top: 100%;
  &__iframe{
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
  }
}

.fullscreen-button{
  cursor: pointer;
  position: absolute;
  right: 2rem;
  bottom: 1.5rem;
  z-index: 10;
  color: white;
  display: inline-block;
  background: black;
  height: 54px;
  width: 64px;
  &__icon{
    transform: translate(0, -5px);
  }
}

.notice {
  background: black;
  position: absolute;
  color: white;
  width: calc(100% - $margin*2);
  margin: $margin;
  display: flex;
  justify-content: space-between;
  &__text {
    padding: 0 $margin-small;
    font-size: 80%;
  }
  &__close {
    padding: $margin-small;
    font-weight: bold;
    font-size: 120;
    cursor: pointer;
  }
}
</style>
