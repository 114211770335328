import { chainIds } from "./constants.js"
import {
  createMediaByCreatorQuery,
  createMediaByOwnerQuery,
  firebaseApp as firebase,
  getChainId,
  getCurrentAccount,
  handleAccountsChanged,
  zoraGraph,
  zoraGraphRinkeby,
  firestore,
  createMediaManyQuery
} from "./services.js"
import detectEthereumProvider from "@metamask/detect-provider"
import DOMPurify from "dompurify"
import marked from "marked"
import Web3 from "web3"

const db = firestore
const usersRef = db.collection("users")
const profilesRef = db.collection("profiles")

export default {
  namespaced: true,
  state: () => ({
    // ETH Address
    account: null,
    chainId: null,
    web3: null,
    // Firebase Credentials
    credentials: {
      photoURL: null,
      displayName: null,
      uid: null,
    },
  }),
  getters: {
    hasAccount: state => state.account ? true : false,
    hasCredentials: state => state.credentials.uid ? true : false,
    getUid: state => state.credentials.uid,
    getDisplayName: state => state.credentials.getDisplayName,
    summerisedAddress: state => {
      // TODO: retrive ens name
      if (!state.account) return ""
      if (state.account.indexOf("0x") === 0) {
        const start = state.account.slice(0, 6)
        const end = state.account.slice(-6)
        return `${start}...${end}`
      }

      return state.account
    },
    getAccount: state => state.account,
  },
  mutations: {
    setAccount: (state, payload) => state.account = payload,
    setCredentials: (state, payload) => state.credentials = { ...state.credentials, ...payload },
    setChainId: (state, payload) => {
      if (chainIds[payload] !== undefined) {
        state.chainId = chainIds[payload]
      } else {
        state.chainId = "Please Change Chain"
      }
    },
    setWeb3: (state, payload) => state.web3 = payload,
    reset: (state) => {
      state.account = null
      state.chainId = null,
        state.web3 = null,
        state.credentials = {
          photoURL: null,
          displayName: null,
          uid: null,
        }
    },
  },
  actions: {
    connectToMetaMask: async ({ commit, dispatch }) => {
      const provider = await detectEthereumProvider()
      if (provider) {
        const account = await getCurrentAccount()
        const chainId = await getChainId()
        const web3 = new Web3(provider)
        commit("setAccount", account)
        commit("setChainId", chainId)
        commit("setWeb3", web3)
        dispatch("handleMetaMaskChanges")
      } else {
        // TODO: notifiy user to install metamask
        console.log("install meta mask")
      }
    },
    handleMetaMaskChanges: ({ dispatch, commit }) => {
      // TODO: don't reley on window.ethereum
      window.ethereum.on("chainChanged", (chainId) => {
        commit("setChainId", chainId)
        // console.log(`New chainID: ${chainId}`)
      })

      window.ethereum.on("accountsChanged", (accounts) => {
        dispatch("signOut")
        const newAccount = handleAccountsChanged(accounts)
        if (newAccount) {
          commit("reset")
          // dispatch('connectAndSignInViaMetaMask')
        } else {
          commit("reset")
        }
      })
    },
    signInViaMetaMask: async ({ state }) => {
      if (!state.web3) {
        // console.log("Connect to MetaMask first")
        return
      }

      try {
        // TODO: notify user on progress of signing in
        // get nonce from firebase functions
        const getNonce = firebase.functions().httpsCallable("getNonce")
        const nonce = (await getNonce({ account: state.account })).data

        // wait for signiture from metamask
        const signiture = (await state.web3.eth.personal.sign(nonce, state.account))
        // Verify signiture and recive auth token from firebase functions
        const getAuthToken = firebase.functions().httpsCallable("getAuthToken")
        const token = (await getAuthToken({ account: state.account, signiture })).data
        // Sign in the user
        await firebase.auth().signInWithCustomToken(token)
      } catch (error) {
        console.error("failed to verify metamask account.", error)
      }
    },
    connectAndSignInViaMetaMask: async ({ dispatch }) => {
      await dispatch("connectToMetaMask")
      await dispatch("signInViaMetaMask")
    },
    signOut: async () => {
      try {
        await firebase.auth().signOut()
      } catch (error) {
        console.error(error.message)
      }
    },
    updateDisplayName: async ({ state }) => {
      // TODO: ENS display name
      if (state.credentials.displayName === null) {
        try {
          await firebase.auth().currentUser.updateProfile({
            displayName: state.account,
          })
        } catch (error) {
          console.log("ERROR while updating display name", error)
        }
      }
    },
    setAuthStateChangeHandler: ({ commit, dispatch }) => {
      firebase.auth().onAuthStateChanged(async userCredentials => {
        if (userCredentials) {
          // set displayName to metamask account as default
          // TODO: check ens name
          // TODO: profile image from account number

          // update user state
          commit("setCredentials", userCredentials)
          dispatch("updateDisplayName")
        } else {
          // TODO: check other doc to see what to do here
          dispatch("signInViaMetaMask")
        }
      })
    },
  },
  modules: {
    profile: {
      namespaced: true,
      state: () => ({
        user: {
          exists: null,
          ethAccount: null,
          displayName: null,
          bio: null,
          parsedBio: null,
          role: null,
        },
        ownedMedia: [],
        ownedMediaLoaded: false,
        saves: [],
        savesLoaded: false,
        createdMedia: [],
        createdMediaLoaded: false,
      }),
      getters: {
        hasMetaMask: async () => {
          // user has account
          // if (!rootState.user.account) return false
          // // user has uid
          // if (!rootState.user.credentials.uid) return false
          const provider = await detectEthereumProvider()
          if (!provider) {
            console.log("no meta mask")
            return false
          }

          return true
        },
      },
      mutations: {
        setExists: (state, exists) => state.user.exists = exists,
        setProfile: (state, profile) => {
          console.log("setting profile", profile)
          // state.ethAccount = profile.ethAccount
          // state.displayName = profile.displayName
          // state.bio = profile.bio
          // state.parsedBio = profile.parsedBio
          // state.role = profile.role
          console.log("state before", state.user)
          state.user = { ...state.user, ...profile }
          console.log("state after", state.user)
        },
        setBio: (state, bio) => state.user.bio = bio,
        setDisplayName: (state, displayName) => state.user.displayName = displayName,
        setOwnedMedia: (state, ownedMedia) => state.ownedMedia = ownedMedia,
        setOwnedMediaLoaded: (state, loadedState) => state.ownedMediaLoaded = loadedState,
        setSaves: (state, saves) => state.saves = saves,
        setSavesLoaded: (state, loadedState) => state.savesLoaded = loadedState,
        setCreatedMedia: (state, createdMedia) => state.createdMedia = createdMedia,
        setCreatedMediaLoaded: (state, loadedState) => state.createdMediaLoaded = loadedState,
        reset: state => {
          state.user = {
            exists: null,
            ethAccount: null,
            displayName: null,
            bio: null,
            parsedBio: null,
          }
          state.ownedMedia = []
          state.ownedMediaLoaded = false
          state.createdMedia = []
          state.createdMediaLoaded = false
          state.saves = []
          state.savesLoaded = false
        },
      },
      actions: {
        reset: ({ commit }) => commit("reset"),
        setProfile: ({ commit }, profile) => {
          // parse markdown
          if (profile.bio) {
            const converted = marked(profile.bio)
            const purified = DOMPurify.sanitize(converted, { ALLOWED_TAGS: ["a", "p", "strong", "em"] })
            profile.parsedBio = purified
          }
          commit("setProfile", profile)
        },
        load: async ({ dispatch, commit }, ethAccount) => {
          if (!ethAccount) return
          // TODO: check is valid eth account
          const query = profilesRef.where("ethAccount", "==", ethAccount)
          try {
            const querySnapshot = await query.get()
            if (querySnapshot.empty) {
              commit("setExists", false)
              dispatch("setProfile", { ethAccount })
              dispatch("requestOwnedMedia")
              dispatch("requestSaves")
              dispatch("requestCreatedMedia")
              return
            }
            querySnapshot.forEach(doc => {
              commit("setExists", true)
              dispatch("setProfile", doc.data())
              dispatch("requestOwnedMedia")
              dispatch("requestSaves")
              dispatch("requestCreatedMedia")
            })
          } catch (error) {
            console.error(error)
          }
          return
        },
        loadFromDisplayName: async ({ dispatch, commit }, displayName) => {
          if (!displayName) return
          const query = profilesRef.where("displayName", "==", displayName)
          try {
            const querySnapshot = await query.get()
            querySnapshot.forEach(doc => {
              commit("setExists", true)
              dispatch("setProfile", doc.data())
              dispatch("requestOwnedMedia")
              dispatch("requestSaves")
              dispatch("requestCreatedMedia")
            })
          } catch (error) {
            console.error(error)
          }
          return
        },
        update: async ({ rootState, dispatch }, { bio, displayName }) => {
          const userRef = usersRef.doc(rootState.user.credentials.uid)
          const profile = {
            ethAccount: rootState.user.account,
            displayName: displayName.toLowerCase(),
            bio: DOMPurify.sanitize(bio, { ALLOWED_TAGS: [] }),
          }
          await userRef.set({
            profile,
          }, { merge: true })
          dispatch("setProfile", profile)
          return
        },
        // TODO: load saves, maybe just add a snippet to profile on save
        // TODO: load nfts
        requestOwnedMedia: async ({ state, commit }) => {
          if (!state.user.ethAccount) return

          // get owned media from zora graph
          const getOwnedMediaMany = createMediaManyQuery(state.user.ethAccount, 'owner')
          console.log("query", getOwnedMediaMany)
          const { medias, chaninId } = await getOwnedMediaMany()

          // no owned media
          if (!medias || medias.length === 0) {
            commit("setOwnedMediaLoaded", true)
            return
          }

          // ids
          const ownedMediaIds = medias.map(media => media.id)

          // load artworks from firebase
          const artworksRef = db.collection("artworks")
          const query = artworksRef.where("zoraMediaId", "in", ownedMediaIds)
          const querySnapshot = await query.get()
          const ownedMediaFirebase = querySnapshot.docs.map(doc => doc.data())

          // no olta NFT's
          if(!ownedMediaFirebase || ownedMediaFirebase.length === 0){
            commit("setOwnedMediaLoaded", true)
            return
          }

          // combine results
          const ownedMedia = ownedMediaFirebase.map(artwork => {
            if(!artwork.zoraMediaId) return {...artwork, type: 'coming-soon' }
            // TODO: include auction
            const auction = undefined
            // const auction = auctionMany.find(auction => auction.media.id === artwork.zoraMediaId)
            const media = medias.find(media => media.id === artwork.zoraMediaId)
            const type = auction ? 'auction' : 'media'
            return {...artwork, media, type }
          })

          commit("setOwnedMedia", ownedMedia)
          commit("setOwnedMediaLoaded", true)
          return
        },
        requestCreatedMedia: async ({ state, commit }) => {
          // TODO: error message display error if they can't get anything
          if (!state.user.ethAccount && state.role !== "artist") return

          const getCreatedMediaMany =  createMediaManyQuery(state.user.ethAccount, 'creator')
          const { medias, chaninId } = await getCreatedMediaMany()

          if (!medias || medias.length < 1) {
            commit("setCreatedMediaLoaded", true)
            return
          }

          // Firebase artworks
          const mediaIds = medias.map(media => media.id)
          const artworksRef = db.collection("artworks")
          // TODO: pageinate if over 10
          const query = artworksRef.where("zoraMediaId", "in", mediaIds)
          const querySnapshot = await query.get()
          console.log("snap shot", querySnapshot)
          const createdMediaFirebase = querySnapshot.docs.map(doc => doc.data())

          // combine results
          const createdMedia = createdMediaFirebase.map(artwork => {
            if(!artwork.zoraMediaId) return {...artwork, type: 'coming-soon' }
            // TODO: include auction
            // TODO: check chainID
            const auction = undefined
            // const auction = auctionMany.find(auction => auction.media.id === artwork.zoraMediaId)
            const media = medias.find(media => media.id === artwork.zoraMediaId)
            const type = auction ? 'auction' : 'media'
            return {...artwork, media, type }
          })
          
          commit("setCreatedMedia", createdMedia)
          commit("setCreatedMediaLoaded", true)
          return
        },
        requestSaves: async ({ state, commit }) => {
          if (!state.user.ethAccount) return

          // saves Ref
          const db = firestore
          const savesRef = db.collection("saves")
          const query = savesRef
            .where("ethAddress", "==", state.user.ethAccount)

          const result = await query.get()
          if (result.empty) {
            commit("setSavesLoaded", true)
            return []
          } else {
            const saves = result.docs.map(doc => doc.data())
            commit("setSaves", saves)
            commit("setSavesLoaded", true)
            return saves
          }
        },
      },
    },
  },
}
