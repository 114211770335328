<template>
  <div
    class="item"
    :class="`item--${type}`"
  >
    <div class="item__thumbnail">
      <img
        v-if="thumbnail"
        class="item__image"
        :src="thumbnail"
      >
      <div v-if="type === 'auction'" class="item__timer"> {{ timer }}</div>
      <div v-if="type === 'coming-soon'" class="item__coming-soon">dropping soon</div>
    </div>
    <router-link class="item__stretched-link" :to="link"></router-link>
    <h3 class="item__name">
      <router-link :to="link">
        {{ title }}
      </router-link>
    </h3>
    <div class="item__users">
      <router-link
        class="item__creator"
        :to="`/profiles/${creator.ethAddress}`"
        >
        <h4 class="item__creator__title">creator</h4>
        <p class="item__creator__id">{{ creator.name }}</p>
      </router-link>
      <router-link
        v-if="owner"
        :to="`/profiles/${owner.id}`"
        class="item__owner">
        <h4 class="item__owner__title">owner</h4>
        <!-- <p class="item__creator__id">{{ owner.id }}</p> -->
        <EthAddress class="item__owner__id" :ethAddress="owner.id" />
      </router-link>
    </div>
    <div v-if="type === 'auction'" class="item__meta">
      <p v-if="isReserveMet" class="item__meta__heighest-bid">
        <b>highest bid</b> {{ heighestBid }}
      </p>
      <p v-else class="item__meta__heighest-bid">
        <b>reserve</b> {{ reserve }}
      </p>
    </div>
     <div v-if="type === 'media'" class="item__meta">
      <div v-if="soldFor" class="item__meta__sold-for">
        <h4 class="item__meta__sold__title">sold for</h4>
        <p class="item__meta__sold__amount">{{ formatUnits(soldFor.amount) }} {{ soldFor.currency.symbol }}</p>
        <p class="item__meta__sold__time">{{ formatRelativeTime(soldFor.inactivatedAtTimestamp) }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, defineComponent, onMounted, ref, watch } from "vue"
import { getImageURL } from "@/services.js"
import EthAddress from './EthAddress.vue'
import dayjs from "dayjs"
import relativeTime from 'dayjs/plugin/relativeTime'
import duration from 'dayjs/plugin/duration'
import { utils } from 'ethers'
export default defineComponent({
  components: {
    EthAddress
  },
  props: {
    item: Object,
    type: {
      type: String,
      validator: (value) => ['auction', 'media', 'coming-soon'].includes(value)
    }
  },
  setup(props) {
    dayjs.extend(relativeTime)
    dayjs.extend(duration)

    const formatRelativeTime = (timestamp) => dayjs(parseInt(timestamp) * 1000).fromNow()
    const title = ref(props.item.title)
    const link = ref(`/artworks/${props.item.uri}`)
    const thumbnail = ref()

    const isReserveMet = computed(() =>{
      if(!props.item.auction) return
      return props.item.auction.currentBid ? true : false
    })

    const reserve = computed(() => {
      // TODO: format all currencys
      if (!props.item.auction) {
        return
      }

      const currencyId = props.item.auction.auctionCurrency.id
      const reserve = props.item.auction.reservePrice

      // ETH
      if (currencyId === '0x0000000000000000000000000000000000000000') {
         return utils.formatEther(reserve) + ' ETH'
      }
    })

    const heighestBid = computed(() => {
      // TODO: format all currencys
      if (!isReserveMet.value) {
        return
      }

      const currencyId = props.item.auction.auctionCurrency.id
      const amount = props.item.auction.currentBid.amount

      // ETH
      if (currencyId === '0x0000000000000000000000000000000000000000') {
         return utils.formatEther(amount) + ' ETH'
      }
    })

    // Timer
    const timer = ref()
    const calcDurationLeft = () => {
      if (props.item.auction) {
        return dayjs(props.item.auction.expectedEndTimestamp * 1000) - dayjs()
      }
    }

    const durationLeft = ref()

    watch([durationLeft], () => {
      if (!props.item.auction) {
        return
      }

      if (props.item.auction.status === 'Pending'){
        timer.value = 'dropping soon'

        return
      }

      if (!isReserveMet.value) {
        timer.value = 'place bid'

        return
      }

      if (durationLeft.value < 0) {
        timer.value = 'ended'

        return
      }

      const daysLeft = dayjs.duration(durationLeft.value).asDays()

      if (daysLeft > 1) {
        timer.value = dayjs.duration(durationLeft.value).humanize(true)

        return
      }

      // start countdown ticker
      setTimeout(() => {
        durationLeft.value = calcDurationLeft()
      }, 1000)

      timer.value = dayjs.duration(durationLeft.value).format("HH[h] mm[m] ss[s]")
    })

    durationLeft.value = calcDurationLeft()

    const getUser = async (id) => {
      // TODO: firebase retrieve from profiles
      return {
        ethAddress : id,
        name: 'Owner Name',
        displayImage: ''
      }
    }

    const creator = computed(() => {
      const creator = { ...props.item.artist }

      // no eth address fallback
      if (!props.item.artist.ethAddress) {
        creator.ethAddress = creator.uri
      }

      return creator
    })

    const owner = computed(() => {
      if (props.type === "auction") {
        return props.item.auction.tokenOwner
      }

      if (props.type === "media") {
        return props.item.media.owner
      }
    })

    const soldFor = computed(() => {
      if (props.type === "media") {
        if (!props.item.media) return
        if (!props.item.media.inactiveBids) return

        const finalizedBids = props.item.media.inactiveBids.filter(bid => bid.type === 'Finalized')

        if (finalizedBids.length) {
          //HACK: should order by date
          return finalizedBids[0]
        }
      }
    })

    onMounted(async () => {
      if (props.item.thumbnail) {
        thumbnail.value = await getImageURL(`thumbnails/${props.item.thumbnail}`)
      }
    })

    return {
      title,
      link,
      thumbnail,
      creator,
      owner,
      timer,
      isReserveMet,
      reserve,
      heighestBid,
      soldFor,
      formatRelativeTime,
      formatUnits: utils.formatUnits,
    }
  },
})
</script>

<style lang="scss" scoped>
  .item {
    position: relative;
    padding: $margin;
    border: solid $border-width transparent;
    &__stretched-link {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 1;
    }
    &:hover {
      @extend %gradient-border;
      border-width: $border-width ;
    }
    & a {
      @include plain-link;
    }
    &__thumbnail {
      position: relative;
      width: 100%;
      padding-top: 100%;
      background: $secondary-background;
    }
    &__image {
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    &__timer {
      z-index: 2;
      position: absolute;
      font-weight: bold;
      top: 0;
      left: 0;
      padding: $margin-small $margin;
      color: $primary-background;
      background: $gradient-highlight;
    }
    &__coming-soon {
      z-index: 2;
      position: absolute;
      font-weight: bold;
      top: 0;
      left: 0;
      padding: $margin-small $margin;
      color: $primary-background;
      background: $primary-color;
    }
    &__name {
      font-size: 200%;
      margin: $margin-small 0;
      &::after {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
      }
    }
    &__users {
      display: flex;
      flex-wrap: wrap;
      gap: $margin-small;
    }
    &__creator, &__owner {
      flex-grow: 1;
      position: relative;
      z-index: 2; // make clickable
      &__title {
        margin: 0;
      }
      &__id {
        margin: 0;
        text-decoration: underline;
        font-size: 120%;
      }
      &:hover {
        opacity: 0.7;
      }
    }
    &__meta {
      margin-top: $margin-small;
      &__sold {
        &__title {
          margin: 0px;
        }
        &__amount {
          margin: 0px;
          font-size: 120%;
        }
        &__time {
          margin: 0px;
          font-size: 80%;
          color: $tertiary-color;
        }
      }
    }
    &.--black-background {
      color: $primary-background;
      & a {
        @include plain-link($primary-background);
      }
    }
  }
</style>
