import { createRouter, createWebHistory } from "vue-router"
import Home from "./components/Home.vue"
import { pathToTitle } from "./helper.js"

const routes = [
  {
    component: Home,
    meta: {
      title: "Olta / Home",
    },
    path: "/",
  },
  {
    component: () => import("./components/Artworks.vue"),
    meta: {
      title: "Olta / Art",
    },
    path: "/artworks",
  },
  {
    component: () => import("./components/ArtworkPage.vue"),
    meta: {
      title: "Olta / Art",
    },
    path: "/artworks/:artworkURI",
  },
  {
    component: () => import("./components/ArtworkPage.vue"),
    meta: {
      title: "Olta / Art",
    },
    path: "/artworks/:artworkURI/:saveID",
  },
  {
    component: () => import("./components/Profiles.vue"),
    meta: {
      title: "Olta / Profiles",
    },
    path: "/profiles",
  },
  {
    component: () => import("./components/Profile.vue"),
    meta: {
      // title: "Olta / Profile",
    },
    path: "/profiles/:ethAddress",
  },
  {
    component: () => import("./components/About.vue"),
    meta: {
      title: "Olta / About",
    },
    path: "/about",
  },
  {
    component: () => import("./components/FAQ.vue"),
    meta: {
      title: "Olta / FAQ",
    },
    path: "/faq",
  },
  {
    component: () => import("./components/Docs.vue"),
    meta: {
      title: "Olta / FAQ",
    },
    path: "/docs",
  },
  {
    component: () => import("./components/CreateAuction.vue"),
    meta: {
      title: "Olta / Create Auction",
    },
    path: "/create-auction",
  },
  {
    component: () => import("./components/AdminManageAuctions.vue"),
    meta: {
      title: "Olta / Manage Auctions",
    },
    path: "/manage-auctions",
  },
  {
    component: () => import("./components/MintForm.vue"),
    meta: {
      title: "Olta / Mint",
    },
    path: "/mint",
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    }

    return { top: 0, left: 0 }
  },
})

router.beforeEach((to) => {
  const title = to?.meta?.title || pathToTitle(to.path)

  if (title) {
    document.title = title
  }

  const description = to?.meta?.description

  if (description) {
    const tag = document.head.querySelector('meta[name="description"]')

    tag.setAttribute("content", description)
  }
})

export default router
