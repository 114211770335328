import detectEthereumProvider from "@metamask/detect-provider"
import firebase from "firebase/app"
import { gql, GraphQLClient } from "graphql-request"
import marked from "marked"

import "firebase/auth"
import "firebase/firestore"
import "firebase/functions"
import "firebase/storage"

import { firebaseConfig, firebaseStagingConfig, MAINNET, RINKEBY } from "@/constants.js"
import { addresses, Zora, validateBytes32 } from "@zoralabs/zdk"
import { providers, utils } from "ethers"
import { ref } from "vue"

// check if staging
const config = process.env.VUE_APP_STAGING ? firebaseStagingConfig : firebaseConfig

export const firebaseApp = firebase.initializeApp(config)

const getFirebaseServices = () => {
  const firestore = firebaseApp.firestore()
  const storage = firebaseApp.storage()
  if(location.hostname === 'localhost') {
    firestore.useEmulator("localhost", 8181)
    storage.useEmulator("localhost", 9199)
  }
  const storageRef = storage.ref()
  return {
    firestore,
    storage,
    storageRef
  }
}

export const { firestore, storage, storageRef } = getFirebaseServices()

export const getImageURL = async (imagePath) => {
  // console.log(storageRef.child(imagePath))
  try {
    const url = await storageRef.child(imagePath).getDownloadURL()
    // console.log(imagePath, url)
    return url
  } catch (error) {
    switch (error.code) {
      case "storage/object-not-found":
        // File doesn't exist
        console.error("file doesn't exist", error)
        break
      case "storage/unauthorized":
        // User doesn't have permission to access the object
        console.error("User doesn't have permission to access the object", error)
        break
      case "storage/canceled":
        // User canceled the upload
        console.error("user canceled", error)
        break
      case "storage/unknown":
        // Unknown error occurred, inspect the server response
        console.error("Unknown error occurred, inspect the server response", error)
        break
      default:
        console.log("something went wrong", error)
        break
    }
  }
}

// Helps with managing the feedback the user recives from any tx on ethereum
export const createTxFeedbackHandler = () => {
  const tx = ref(null)
  const txState = ref("not")
  const txError = ref(null)
  const canReset = ref(true)
  const reset = () => {
    txState.value = "not"
    tx.value = null
  }
  // TODO: reorder properties and apply options
  // e.g (txPromise, callback, options { confirmations: number, reset: boolean})
  const handleTx = async (txPromise, confirmations, callback) => {
    try {
      txState.value = "pending"
      tx.value = await txPromise
      txState.value = "confirming"
      await tx.value.wait(confirmations)
      txState.value = "success"

      // HACK: button resets state after 3s
      setTimeout(() => {
        callback()
        if (canReset.value) reset()
      }, 3000)
    } catch (error) {
      txState.value = "error"
      console.error("tx error", error)
      txError.value = error

      setTimeout(reset, 1500)
    }
  }

  return {
    tx,
    txState,
    handleTx,
    canReset,
  }
}

// UTILITIES for connecting to etherium via MetaMask
// refrence: https://docs.metamask.io/guide/ethereum-provider.html#using-the-provider

// HANDLE CHAIN (network)

// const handleChainChanged = (_chainId) => {
//   // reload the page
//   // window.location.reload()
//   // console.log(`New chainID: ${_chainId}`)
// }

export const getChainId = async () => {
  if(window.ethereum === undefined) return
  try{
    return await window.ethereum.request({ method: "eth_chainId" })
  } catch {
    return
  }
}

// HANDLE ACCOUNTS (network)

export const handleAccountsChanged = (accounts) => {
  if (accounts.length === 0) {
    // MetaMask is locked or the user has not connected any accounts
    console.log("Please connect to MetaMask.")
    return null
  } else {
    return accounts[0]
    // Do any other work!
  }
}

export const getCurrentAccount = async () =>
  handleAccountsChanged(await window.ethereum.request({ method: "eth_requestAccounts" }))

// CONNECT TO METAMASK

export const connectToMetaMask = async () => {
  try {
    getCurrentAccount()
  } catch (err) {
    if (err.code === 4001) {
      console.log("Please connect to MetaMask")
    } else {
      console.error(err)
    }
  }
}

export const getEthersProvider = async () => {
    const metamaskProvider = await detectEthereumProvider({ silent: true })
    if(!metamaskProvider) return

    const provider = new providers.Web3Provider(metamaskProvider)
    return provider
}

export const createComsForPlatform = (targetRef, targetOrigin) => {
  // TODO: get target origin from metadata
  const iframeWindow = targetRef.contentWindow

  const save = () => {
    iframeWindow.postMessage({
      type: "save",
    }, targetOrigin)
  }
  const load = (load) => {
    iframeWindow.postMessage({
      type: "load",
      load,
    }, targetOrigin)
  }
  const controllerUpdate = ({ property, value }) => {
    iframeWindow.postMessage({
      type: "controllerUpdate",
      property,
      value,
    }, targetOrigin)
  }
  const getControllers = () => {
    iframeWindow.postMessage({
      type: "controllers",
    }, targetOrigin)
  }

  let setControllersHandler = () => console.log("no set controller handler set")
  let getSaveHandler = () => console.log("no get save handler set")
  const onSetControllers = (handler) => setControllersHandler = handler
  const onGetSave = (handler) => getSaveHandler = handler

  const start = () => {
    if (!iframeWindow) {
      return
    }

    window.addEventListener("message", (event) => {
      // console.log('match', event.origin !== targetOrigin,"event " + event.origin, "target " + targetOrigin)
      if (event.origin !== targetOrigin) {
        return
      }

      if (!event.data.type) {
        return
      }

      switch (event.data.type) {
        case "controllers":
          setControllersHandler(event.data.controllers)
          break
        case "save":
          getSaveHandler(event.data.save)
          break
      }
    }, false)
  }
  return {
    start,
    controllerUpdate,
    onSetControllers,
    onGetSave,
    save,
    load,
    getControllers,
  }
}

export const createComsForArtwork = () => {
  const targetWindow = window.parent
  const targetOrigin = (location.hostname === "localhost") ? "http://localhost:8080" : "https://olta.art"

  let saveHandler = () => console.log("no save handler set")
  let loadHandler = () => console.log("no load handler set")
  let controllerUpdateHandler = () => console.log("no controller update handler set")

  const onSave = (handler) => saveHandler = handler
  const onLoad = (handler) => loadHandler = handler
  const onControllerUpdate = (handler) => controllerUpdateHandler = handler

  let controllers = []
  const setControllers = (newControllers) => controllers = newControllers

  const start = () => {
    if (!window.parent) {
      return
    }

    window.addEventListener("message", (event) => {
      if (event.origin !== targetOrigin) {
        return
      }

      if (!event.data.type) {
        return
      }

      switch (event.data.type) {
        case "load":
          loadHandler(event.data.load)
          break
        case "controllerUpdate":
          controllerUpdateHandler(event.data)
          break
        case "controllers":
          targetWindow.postMessage({
            type: "controllers",
            controllers,
          }, targetOrigin)
          break
        case "save":
          targetWindow.postMessage({
            type: "save",
            save: saveHandler(event.data),
          }, targetOrigin)
          break
      }
    }, false)
  }

  return {
    onSave,
    onLoad,
    onControllerUpdate,
    setControllers,
    start,
  }
}

export const getSigner = async () => {
  const provider =  await detectEthereumProvider()
  const ethersProvider = new providers.Web3Provider(provider)
  return ethersProvider.getSigner()
}

// TODO: try const zora = getZora() and on wallet change update zora
export const getZora = async () => {
  const provider = await detectEthereumProvider()
  const chainId = await provider.request({ method: "eth_chainId" })
  if (chainId === "0x4") {
    const rinkebyMedia = addresses["rinkeby"].media
    const rinkebyMarket = addresses["rinkeby"].market
    const ethersProvider = new providers.Web3Provider(provider)
    const signer = ethersProvider.getSigner()
    return new Zora(signer, 4, rinkebyMedia, rinkebyMarket)
  }
  if (chainId === "0x1") {
    console.log("your on mainnet")
    const ethersProvider = new providers.Web3Provider(provider)
    const signer = ethersProvider.getSigner()
    return new Zora(signer, 1)
  }
}

// NOTE[george]: Adapted from zora/ZDK to allow for ethers 'signers' not just wallets
// see original here https://github.com/ourzora/zdk/blob/master/src/utils.ts
// ethers _signTypedData is experimental!
export const signPermitMessage = async (
  owner,
  toAddress,
  mediaId,
  nonce,
  deadline,
  domain
) => {
  const tokenId = mediaId

  return new Promise(async (res, reject) => {
    try {
        const types = {
          Permit: [
            { name: "spender", type: "address" },
            { name: "tokenId", type: "uint256" },
            { name: "nonce", type: "uint256" },
            { name: "deadline", type: "uint256" }
          ],
        }
        const value = {
          spender: toAddress,
          tokenId,
          nonce,
          deadline
        }
      const signiture = await owner._signTypedData(domain, types, value)
      const response = utils.splitSignature(signiture)

      res({
        r: response.r,
        s: response.s,
        v: response.v,
        deadline: deadline.toString()
      })
    } catch (e) {
      console.error(e)
      reject(e)
    }
  })
}

// NOTE[george]: Adapted from zora/ZDK to allow for ethers 'signers' not just wallets
// see original here https://github.com/ourzora/zdk/blob/master/src/utils.ts
// ethers _signTypedData is experimental!
export const  signMintWithSigMessage = async (
  owner,
  contentHash,
  metadataHash,
  creatorShareBN,
  nonce,
  deadline,
  domain
) => {
  try {
    validateBytes32(contentHash)
    validateBytes32(metadataHash)
  } catch (err) {
    return Promise.reject(err.message)
  }

  const creatorShare = creatorShareBN.toString()

  return new Promise(async (res, reject) => {
    try {
      const types = {
        MintWithSig: [
          { name: 'contentHash', type: 'bytes32' },
          { name: 'metadataHash', type: 'bytes32' },
          { name: 'creatorShare', type: 'uint256' },
          { name: 'nonce', type: 'uint256' },
          { name: 'deadline', type: 'uint256' },
        ],
      }
      const value = {
        contentHash,
        metadataHash,
        creatorShare,
        nonce,
        deadline,
      }
      const signiture = await owner._signTypedData(domain, types, value)
      const response = utils.splitSignature(signiture)
      res({
        r: response.r,
        s: response.s,
        v: response.v,
        deadline: deadline.toString(),
      })
    } catch (e) {
      console.error(e)
      reject(e)
    }
  })
}

export const zoraGraphRinkeby = new GraphQLClient(RINKEBY);
export const zoraGraph = new GraphQLClient(MAINNET)

const mediaObject = `
id
contentURI
metadataURI
creator {
  id
}
owner {
  id
}
creatorBidShare
ownerBidShare
prevOwnerBidShare
prevOwner{
  id
}
currentAsk {
  currency{
    symbol,
    name,
    id,
    decimals,
  }
  amount
}
currentBids {
  id
  currency{
    symbol,
    name,
    id,
    decimals
  }
  amount
  sellOnShare
  bidder{
    id
  }
  recipient{
    id
  }
  createdAtTimestamp
}
inactiveBids{
  type
  currency {
    symbol
  }
  amount
  inactivatedAtTimestamp
}
`

export const createMediaQuery = id => {
  return gql`{
    media(id: "${id}") {
      ${mediaObject}
    }
  }`
}

export const createMediaByOwnerQuery = ownerId => {
  return gql`{
    medias(where: { owner: "${ownerId}" }) {
      ${mediaObject}
    }
  }`
}

export const createMediaByCreatorQuery = creatorId => {
  return gql`{
    medias(where: { creator: "${creatorId}" }) {
      ${mediaObject}
    }
  }`
}

export const createMediaManyQuery = (input, type) => {
  const validTypes = ['owner', 'creator']
  if(!validTypes.find(validType => validType === type)) return

  // construct query
  const where = `{ ${type}: "${input}" }`
  const query = gql`
  {
    medias(where: ${where}) {
      ${mediaObject}
    }
  }
  `

  // return query to correct zoraGraph
  return async () => {
    const chainID = await getChainId()

    // Rinkeby
    if (chainID === "0x4") {
      const resp = await zoraGraphRinkeby.request(query)
      return {...resp, chainId: "4" }
    }

    // Defualt to Mainnet
    const resp = await zoraGraph.request(query)

    return {...resp, chainId: "1" }
  }
}

export const loadProfiles = async () => {
  const profilesRef = firestore.collection("profiles")

  try {
    const profilesQuery = await profilesRef.get()

    if (!profilesQuery.empty) {
      return profilesQuery.docs
          // Extract data object for each doc
        .map(doc => doc.data())
        // Keep only records having a displayName
        .filter(data => !!data.displayName)
        // Format bio if available
        .map(data => ({ ...data, bio: marked(data.bio) }))
    }
  } catch (error) {
    // TODO: handle errors
    console.error("Error while loading profile from database", error)
  }
}

export const loadArtworks = async () => {
  const artworksRef = firestore.collection("artworks")

  try {
    const artworksQuery = await artworksRef.orderBy("zoraMediaId").get()

    if (!artworksQuery.empty) {
      return artworksQuery.docs
        .map(doc => doc.data())
        .map(data => ({ ...data, description: marked(data.description), type: "coming-soon" }))
    }
  } catch (error) {
    // TODO: handle errors
    console.error("Error while loading artwork from database", error)
  }
}

export const loadAuctions = async (mediaIdMany) => {
  console.log("media ids", mediaIdMany)

  const query =  gql`{
    reserveAuctions(where:
      {
        media_in: ${JSON.stringify(mediaIdMany)},
        status_in: [Active, Pending]
      }) {
      id
      status
      reservePrice
      expectedEndTimestamp
      auctionCurrency {
        name
        symbol
        decimals
        id
      }
      currentBid {
        amount
        bidder {
          id
        }
      }
      tokenOwner {
        id
      }
      media {
        id
        owner {
          id
        }
        creator {
          id
        }
      }
    }
  }`

  try {
    //TODO: add mainnet zoragraph
    const resp = await zoraGraph.request(query)

    return resp.reserveAuctions
  } catch (error) {
    console.error(error)
  }
}

export const loadMedias = async (mediaIdMany) => {
  console.log("media ids", mediaIdMany)

  const query =  gql`{
    medias(where:
      {
        id_in: ${JSON.stringify(mediaIdMany)}
      }) {
      id
      contentURI
      metadataURI
      creator {
        id
      }
      owner {
        id
      }
      creatorBidShare
      ownerBidShare
      prevOwnerBidShare
      currentAsk {
        currency {
          symbol
          name
          id
          decimals
        }
        amount
      }
      currentBids {
        id
        currency {
          symbol
          name
          id
          decimals
        }
        amount
        sellOnShare
        bidder {
          id
        }
        recipient {
          id
        }
        createdAtTimestamp
      }
      inactiveBids {
        type
        currency {
          symbol
          name
          id
          decimals
        }
        amount
        inactivatedAtTimestamp
      }
    }
  }`

  try {
    //TODO: add mainnet zoragraph
    const resp = await zoraGraph.request(query)

    return resp.medias
  } catch (error) {
    console.error(error)
  }
}
