// Helps capitalize string content
export const capitalize = ([first, ...rest] = "") => `${first.toUpperCase()}${rest.join("")}`

// Helps prettify paths to produce default document titles
export const pathToTitle = (path = "") =>
  path
    // Parse :artworkURI
    .replaceAll("-", " ")
    // Separate out path parts
    .split("/")
    // Drop leading slash blank entry
    .filter(s => s.length)
    // Capitalize each word
    .map(s => s.split(" ").map(w => capitalize(w)).join(" "))
    // Reassemble
    .join(" / ")

export async function getIframeSrcdoc(src) {
  const content = await fetch(src)
  const text = await content.text()

  const base = document.createElement("base")

  base.setAttribute("href", `${src}/`)

  const parser = new DOMParser()
  const html = parser.parseFromString(text, "text/html")

  html.head.insertBefore(base, html.head.firstChild)

  const serializer = new XMLSerializer()
  const doc = serializer.serializeToString(html)

  return doc
}

// Helps randomly select an array element
export const getRandomFromArray = (a = []) => a[Math.floor(Math.random() * a.length)]
