<template>
  <div class="grid">
    <div
      class="grid__item"
      v-for="(item, index) in items"
      :key="index"
    >
      <slot :item="item" :index="index"></slot>
    </div>
  </div>
</template>

<script>
import { computed, defineComponent } from "vue"

export default defineComponent({
  props: {
    items: Array,
    cols: Number,
  },
  setup: (props) => {
    const numberOfColumns = computed(() => `col-${props.cols ? props.cols : 2}`)
    return {
      numberOfColumns
    }
  },
})
</script>

<style lang="scss" src="../styles/grid.scss" scoped></style>
