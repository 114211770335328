<template>
  <Header />
  <main class="main">
    <router-view />
  </main>
  <footer class="footer">
    <div class="footer__contact">
      <h4>Contact</h4>
      <a href="https://discord.gg/wnj2qW8pH6" target="_blank" rel="noopener">
        Discord &rsaquo;
      </a>
    </div>
    <div class="footer__social">
      <h4>Social</h4>
      <ul v-if="social">
        <li v-for="item in social" :key="item.label">
          <a :href="item.url" target="_blank" rel="noopener">
            {{item.label}}
          </a>
          &rsaquo;
        </li>
      </ul>
    </div>
    <div class="footer__partners">
      <h4>Partners</h4>
      <ul v-if="social">
        <li v-for="item in partners" :key="item.label">
          <a :href="item.url" target="_blank" rel="noopener">
            {{item.label}}
          </a>
          &rsaquo;
          <p class="footer__partners__description">{{ item.description }}</p>
        </li>
      </ul>
    </div>
    <div class="footer__investors">
      <h4>Investors</h4>
      <ul v-if="investors">
        <li v-for="item in investors" :key="item.label">
          <a :href="item.url" target="_blank" rel="noopener">
            {{item.label}}
          </a>
          &rsaquo;
          <p class="footer__investors__description">{{ item.description }}</p>
        </li>
      </ul>
    </div>
  </footer>
  <div class="site-notice --staging" v-if="staging">
    <p>This is the staging site, for the real deal, <a href="https://olta.art">visit olta.art</a></p>
  </div>
</template>

<script>
import Header from "./Header.vue"

export default {
  name: "App",
  components: {
    Header,
  },
  data: () => ({
    social: [
      {
        label: "Twitter",
        url: "https://twitter.com/oltaart",
      },
      {
        label: "Instagram",
        url: "https://instagram.com/olta.art/",
      },
      {
        label: "Cent",
        url: "https://beta.cent.co/hool/",
      },
    ],
    investors: [
      {
        label: "Arweave",
        url: "https://www.arweave.org/",
        description: "A protocol that allows you to store data permanently, sustainably, with a single upfront fee"
      },
      {
        label: "Cameron Hejazi",
        url: "https://twitter.com/chejazi",
        description: "CEO & Co-Founder of Cent and Valuables"
      },
    ],
    partners: [
      {
        label: "Zora",
        url: "https://zora.co",
        description: "A universal media registry protocol"
      },
    ],
    staging: process.env.VUE_APP_STAGING
  })
}
</script>

<style lang="scss" src="../styles/main.scss"></style>

<style lang="scss" scoped>
.main {
  flex: 1 0 calc(100vh - $header-height);
}
.footer {
  background: $secondary-background;
  padding: $margin;
  color: $tertiary-color;
  gap: $margin;
  @media (min-width: 45em) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-areas:
      "contact partners"
      "social investors";
  }
  @media (min-width: 70em) {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-areas:
      "contact social partners investors";
  }
  a {
    @include plain-link($tertiary-color);
    text-decoration: underline;
  }
  ul {
    @include plain-list;
  }
  &__social {
    grid-area: social;
  }
  &__contact {
    grid-area: contact;
  }
  &__partners {
    grid-area: partners;
    &__description {
      margin:$margin-small * 0.5 0 $margin-small 0;
    }
  }
  &__investors {
    grid-area: investors;
    &__description{
      margin:$margin-small * 0.5 0 $margin-small 0;
    }
  }
}

.site-notice {
  z-index: 999;
  position: fixed;
  background: white;
  bottom: 0;
  left: 0;
  padding: $margin-small $margin;
  &.--staging {
    color: white;
    background: #e91e63;
  }
}
</style>
