export const RINKEBY = "https://api.thegraph.com/subgraphs/name/ourzora/zora-v1-rinkeby"
export const MAINNET = "https://api.thegraph.com/subgraphs/name/ourzora/zora-v1"

export const chainIds = {
  ["0x1"]: "Mainnet",
  ["0x4"]: "Rinkeby",
}

export const firebaseConfig = {
  apiKey: "AIzaSyBdcFxWaQtLcbm6WkmJozssWVUSpz-NA8s",
  authDomain: "artevolved-dev.firebaseapp.com",
  databaseURL: "https://artevolved-dev.firebaseio.com",
  projectId: "artevolved-dev",
  storageBucket: "artevolved-dev.appspot.com",
  messagingSenderId: "69945945686",
  appId: "1:69945945686:web:f8658de5be7699e34f15f7",
  measurementId: "G-XRZ83DXX77",
}

export const firebaseStagingConfig = {
  apiKey: "AIzaSyAVWcDdwHuvR1zOgtEsUbGZq-D8uMcgo6s",
  authDomain: "olta-art-staging.firebaseapp.com",
  projectId: "olta-art-staging",
  storageBucket: "olta-art-staging.appspot.com",
  messagingSenderId: "942908307591",
  appId: "1:942908307591:web:a0e5bf458351597213a022",
  measurementId: "G-MJP21CVP69"
}

export const featuredInteractiveMany =[
  {
    zoraMediaId: "7753",
    uri: "edge-light",
    latestVersion: "https://arweave.net/wa5liBEZtIBESMfUSbhpMmpb9ztig5SPssYd607T-so",
    artist: {
      uri: "0xc574a425aa6b3485300f3b80388049c3334a6774",
      name: "DATASYNCED",
      ethAddress: "0xc574a425aa6b3485300f3b80388049c3334a6774"
    },
    title: "Edge Light",
    srcDoc: false
  },
  {
    zoraMediaId: "6410",
    uri: "form",
    latestVersion: "https://arweave.net/_N_D92ONxfZbxxrzT_hQTtdHJueBDxr1pFgSbbyyv0Y",
    artist: {
      uri: "0x8f96aa1c4cbd3b10786c81031c6917bcac66423c",
      name: "Tamrat",
      ethAddress: "0x8f96aa1c4cbd3b10786c81031c6917bcac66423c"
    },
    title: "Form",
    srcDoc: false
  },
  {
    zoraMediaId: "6421",
    uri: "chaos-theory",
    latestVersion: "https://arweave.net/gsMd1l7SboCTfOPI30BdXAidBMXKwp5_dZqmXJazzvg",
    artist: {
      uri: "0x421e7670d4e7530ee2edeb7b7abf16a284d3fbc0",
      name: "Sim",
      ethAddress: "0x421e7670d4e7530ee2edeb7b7abf16a284d3fbc0"
    },
    title: "Chaos Theory",
    srcDoc: false
  }
]

export const erc20Tokens = [
  {
    "name": "Wrapped Ether",
    "address": "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2",
    "symbol": "WETH",
    "decimals": 18,
    "chainId": 1,
    "logoURI":
      "https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2/logo.png",
  },
  {
    "name": "Wrapped BTC",
    "address": "0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599",
    "symbol": "WBTC",
    "decimals": 8,
    "chainId": 1,
    "logoURI":
      "https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599/logo.png",
  },
  {
    "name": "Dai Stablecoin",
    "address": "0x6B175474E89094C44Da98b954EedeAC495271d0F",
    "symbol": "DAI",
    "decimals": 18,
    "chainId": 1,
    "logoURI":
      "https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0x6B175474E89094C44Da98b954EedeAC495271d0F/logo.png",
  },
  {
    "name": "Tether USD",
    "address": "0xdAC17F958D2ee523a2206206994597C13D831ec7",
    "symbol": "USDT",
    "decimals": 6,
    "chainId": 1,
    "logoURI":
      "https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0xdAC17F958D2ee523a2206206994597C13D831ec7/logo.png",
  },
  {
    "name": "USDCoin",
    "address": "0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48",
    "symbol": "USDC",
    "decimals": 6,
    "chainId": 1,
    "logoURI":
      "https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48/logo.png",
  },
  {
    "name": "ChainLink Token",
    "address": "0x514910771AF9Ca656af840dff83E8264EcF986CA",
    "symbol": "LINK",
    "decimals": 18,
    "chainId": 1,
    "logoURI":
      "https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0x514910771AF9Ca656af840dff83E8264EcF986CA/logo.png",
  },
  {
    "name": "Binance Coin",
    "address": "0xb8c77482e45f1f44de1745f52c74426c631bdd52",
    "symbol": "BNB",
    "decimals": 18,
    "chainId": 1,
    "logoURI": "https://etherscan.io/token/images/bnb_28_2.png",
  },
]

export const erc20TokensRinkeby = [
  {
    "name": "Wrapped Ether",
    "address": "0xc778417E063141139Fce010982780140Aa0cD5Ab",
    "symbol": "WETH",
    "decimals": 18,
    "chainId": 4,
    "logoURI":
      "https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0xc778417E063141139Fce010982780140Aa0cD5Ab/logo.png",
  },
  {
    "name": "Dai Stablecoin",
    "address": "0xc7AD46e0b8a400Bb3C915120d284AafbA8fc4735",
    "symbol": "DAI",
    "decimals": 18,
    "chainId": 4,
    "logoURI":
      "https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0xc7AD46e0b8a400Bb3C915120d284AafbA8fc4735/logo.png",
  },
  {
    "name": "Uniswap",
    "address": "0x1f9840a85d5aF5bf1D1762F925BDADdC4201F984",
    "symbol": "UNI",
    "decimals": 18,
    "chainId": 4,
    "logoURI": "ipfs://QmXttGpZrECX5qCyXbBQiqgQNytVGeZW5Anewvh2jc4psg",
  },
  {
    "name": "Wrapped Bitcoin",
    "address": "0x577d296678535e4903d59a4c929b718e1d575e0a",
    "symbol": "WBTC",
    "decimals": 8,
    "chainId": 4,
    "logoURI": "",
  },
]
