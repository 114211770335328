<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    xml:space="preserve"
    :width="width"
    :height="width"
    :viewBox="customViewBox"
  >
    <g
      :fill="fillColor"
      :stroke="strokeColor"
      :stroke-width="strokeWidth"
    >
      <slot/>
    </g>
  </svg>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    customViewBox: {
      type: [String],
      defualt: '0 0 85 85'
    },
    width: {
      type: [Number, String],
      default: 18
    },
    height: {
      type: [Number, String],
      default: 18
    },
    fillColor: {
      type: String,
      default: 'currentColor'
    },
    strokeColor: {
      type: String,
      default: 'currentColor'
    },
    strokeWidth: {
      type: [Number, String],
      default: "2.5"
    }
  }
})
</script>
