<template>
  <FeaturedInteractive
    :artwork="featuredInteractive"
    cta="discover interactive NFTs"
  />
  <Grid
    class="grid--featured"
    v-if="featured.length > 0"
    :items="featured"
    v-slot="slotProps"
    >
    <GridItem :item="slotProps.item" :type="slotProps.item.type" />
  </Grid>
  <div class="featured__loading" v-else>
    <p>Loading Artworks...</p>
  </div>
  <CallToAction
    :tagline="cta.content"
    :buttonLabel="cta.buttonLabel"
    :link="cta.link"
  >WTF OLTA.
  </CallToAction>
</template>

<script>
import { defineComponent, onMounted, ref, reactive } from "vue"
import { gql } from "graphql-request"
import { featuredInteractiveMany } from "../constants.js"
import { loadArtworks, loadMedias, loadAuctions } from "../services.js"
import { getRandomFromArray } from "../helper.js"
import FeaturedInteractive from "./FeaturedInteractive.vue"
import Grid from "./Grid.vue"
import GridItem from "./GridItem.vue"
import CallToAction from "./CallToAction.vue"

export default defineComponent({
  components: {
    FeaturedInteractive,
    Grid,
    GridItem,
    CallToAction
  },
  name: "Home",
  setup() {
    const randomArtwork = getRandomFromArray(featuredInteractiveMany)
    const featuredInteractive = ref(randomArtwork)
    const featured = ref([])

    onMounted(async () => {
      const featuredFirebase = await loadArtworks()
      const getZoraMediaId = (artwork) => artwork.zoraMediaId
      const mediaIdMany = featuredFirebase.filter(getZoraMediaId).map(getZoraMediaId)

      // load auctions
      const auctionMany = await loadAuctions(mediaIdMany)

      // check media with no auctions
      const noAuctionMediaIdMany = mediaIdMany.filter((id) => {
        const match = auctionMany.find(auction => auction.media.id === id)

        return !match
      })

      // load media
      const mediaMany = await loadMedias(noAuctionMediaIdMany)

      // map auctions/media to artworks and define type
      featured.value = featuredFirebase
        .map(artwork => {
          if (artwork.zoraMediaId) {
            // check for auction
            const media = mediaMany.find(media => media.id === artwork.zoraMediaId)
            const auction = auctionMany.find(auction => auction.media.id === artwork.zoraMediaId)
            const type = auction ? "auction" : "media"

            return { ...artwork, auction, media, type }
          }

          return artwork
        })

      featured.value = [
        // dropping soon
        ...featured.value
          .filter(item => !item?.media?.id && !item?.auction?.id),
        // auctions
        ...featured.value
          .filter(item => item?.auction?.id)
          .sort((a,b) => b?.auction?.id - a?.auction?.id),
        // no auctions
        ...featured.value
          .filter(item => item?.media?.id)
          .sort((a,b) => b?.media?.id - a?.media?.id)
      ]
    })

    const cta = reactive({
      buttonLabel: "learn more about us",
      content: "code art experienced how it should be.",
      link: "/about"
    })

    return {
      featuredInteractive,
      featured,
      cta
    }
  },
})
</script>

<style lang="scss" scoped>
.featured {
  &__loading {
    font-size: 1.5rem;
    padding: $margin;
  }
}
</style>
